const Style = ({ attributes, clientId, isStandard }) => {
	const { pricingTables, columnGap, rowGap, textAlign, padding, shadow } = attributes;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			#bptbPricingTables-${clientId} .bptbPricingTables{
				grid-gap: ${rowGap} ${columnGap};
				text-align: ${textAlign};
				padding: ${isStandard ? '30px 0' : '0'}
			}
			#bptbPricingTables-${clientId} .bptbPricingTables .bptbPriceTable{
				padding: ${padding?.styles || '35px 25px'};
				box-shadow: ${shadow?.styles || '0px 0px 30px 0px #6e8faf4d'};
			}
			`.replace(/\s+/g, ' ')
		}} />
		<style>
			{pricingTables?.map((priceTable, index) => {
				const defPriceTableSelect = `#bptbPricingTables-${clientId} .bptbPricingTables #bptbPriceTable-${index} .bptbPriceTable`;
				const priceTableSelect = hasClass => `#bptbPricingTables-${clientId}.is-style-${hasClass} .bptbPricingTables #bptbPriceTable-${index} .bptbPriceTable`;

				return `
					${defPriceTableSelect} .priceName,
					${priceTableSelect('basic')} .priceAmountWrap,
					${priceTableSelect('standard')} .priceAmountWrap{
						color: ${priceTable.color};
					}
					${defPriceTableSelect} .priceLink,
					${priceTableSelect('basic')} .priceName,
					${priceTableSelect('standard')}::before,
					${priceTableSelect('ultimate')} .priceAmountWrap{
						color: #fff;
						background-color: ${priceTable.color};
					}
					${priceTableSelect('basic')} .priceName::before{
						border-top-color: ${priceTable.color};
					}
				`.replace(/\s+/g, ' ')
			})}
		</style>
	</>
}
export default Style;